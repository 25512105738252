import * as React from "react";
import { Link } from "gatsby";

// import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
// import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function HoldOnToTomorrow() {
  return (
    <Layout>
      <Helmet title="Hold On To Tomorrow | Michael Weslander" />

      <h1>Hold On To Tomorrow</h1>
      <h2>Michael Weslander</h2>
      <h4>Key: G</h4>
      <h4>Tempo: 80</h4>
      <Link to="/">List</Link>

      {/* I feel inconsistent */}

      <Columns columns="2">
        <Verse>
          <p>
            <Ch>G</Ch>I struggle with commitment
          </p>
          <p>
            <Ch>Dm</Ch>As the days begin to blur
          </p>
          <p>
            <Ch>C</Ch>So busy, I don't realize I'm bored<Ch>G</Ch>
          </p>
          <p>
            <Ch>G</Ch>I try to go and fix it
          </p>
          <p>
            <Ch>Dm</Ch>By making a few more
          </p>
          <p>
            <Ch>C</Ch>Promises you're learning to ignore<Ch>G</Ch>
          </p>
        </Verse>

        <Verse>
          <p>Now in the heat of battle</p>
          <p>I'm steadfast by your side</p>
          <p>With you through the depths of all dispair</p>
        </Verse>

        <Verse>
          <p>But when we drive through Kansas</p>
          <p>Slower than Molasses</p>
          <p>I miss the dreams I lost out there</p>
        </Verse>

        <Chorus>
          <p>
            <Ch>Em</Ch>I will <Ch>B</Ch>never be
          </p>
          <p>
            <Ch>C</Ch>The one to up and <Ch>G</Ch>leave
          </p>
          <p>
            <Ch>F</Ch>As long as <Ch>F</Ch>we still be<Ch>G</Ch>lieve
          </p>
          <p>
            <Ch>Em</Ch>Stay headed <Ch>B</Ch>westbound
          </p>
          <p>
            <Ch>C</Ch>On our way to Colo<Ch>G</Ch>rado
          </p>
          <p>
            <Ch>F</Ch>Hold on to to<Ch>C</Ch>morrow with <Ch>G</Ch>me
          </p>
        </Chorus>

        <Verse>
          <p>Pass me one of those discs</p>
          <p>We’ve listened to before</p>
          <p>In the black case under my seat on the floor</p>
        </Verse>

        <Verse>
          <p>The one with all the scratches</p>
          <p>Can make it a few more</p>
          <p>Turns around the sun of this I’m sure</p>
        </Verse>

        <Verse>
          <p>And when we drive through Kansas</p>
          <p>Slower than molasses </p>
          <p>We’ll crank that baby, let it roar</p>
        </Verse>

        <Chorus>
          <p>I will never be</p>
          <p>The one to up and leave</p>
          <p>As long as we still believe</p>
          <p>Stay headed westbound on our way to Colorado</p>
          <p>Hold on to tomorrow with me</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
